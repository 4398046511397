<template>
    <svg class="chevron-down" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="3" d="M19 9l-7 7-7-7"></path></svg>
</template>

<script>
export default {
   name: 'chevron-down',
}
</script>

<style scoped>
.chevron-down {
    width: 1.5vw;
}
</style>